import React, { useContext } from "react"
import { BiMenu } from "react-icons/bi"
import { MdOutlineClose } from "react-icons/md"
import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context"
import { HiOutlineShoppingCart } from "react-icons/hi"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  const { state } = useContext(SnipcartContext)
  const isBrowser = () => typeof window !== "undefined"
  const { userStatus, cartQuantity } = state
  return (
    <>
      {/* <div className="relative flex flex-wrap items-center justify-between px-2 py-[0.2rem] text-[0.7rem] bg-zinc-300 dark:bg-zinc-500 text-xs">
        {userStatus === "SignedOut" ? (
          <button className="snipcart-customer-signin">
            Log in
          </button>
        ) : (
          <button className="snipcart-customer-signin">
            My Account
          </button>
        )}
      </div> */}
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-[#F7B334] dark:bg-black">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <AniLink
              to="/"
              paintDrip
              duration={0.5}
              hex={
                isBrowser() && window.localStorage.theme === "dark"
                  ? "#fff"
                  : "#000"
              }
              className="flex items-center"
            >
              <svg
                className="mr-3 h-9 fill-black	dark:fill-white"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 594 252"
                version="1.1"
                viewBox="0 0 594 252"
                xmlSpace="preserve"
              >
                <path d="M240.8 103.3H236v43.3h4.5c12 0 22.2-6.6 22.2-21.6.1-13.9-8.9-21.7-21.9-21.7zM297 11.2L12 57.6v136.8l285 46.4 285-46.4V57.6L297 11.2zM97.8 163.6h-31V86.3h28.7c13.7 0 23 5.3 23 20.1 0 7-2.3 12.6-8.5 15.8v.2c11.1 1.4 15.8 8.9 15.8 19.7 0 16.2-13.9 21.5-28 21.5zm105.4-33.8c0 10-.4 19.8-8.1 27.3-6.5 6.4-16.6 8.6-25.6 8.6s-19.2-2.2-25.7-8.6c-7.7-7.5-8.1-17.3-8.1-27.3V86.3h20.1V127c0 10.1.7 21 13.6 21s13.6-10.9 13.6-21V86.3h20.1v43.5h.1zm41.2 33.8h-28.5V86.3h28.5c21.5 0 39.2 16.9 39.2 38.7 0 21.7-17.8 38.6-39.2 38.6zm120.7 0l-5.6-44.5h-.2l-18.6 44.5h-8L315 119.1h-.2l-6.5 44.5h-20l13.1-77.3h19.8l15.7 41.3 16.6-41.3h20l11.7 77.3h-20.1zm72.5-60.3h-23.9v12.9h22.7v17h-22.7v13.3h23.9v17h-44V86.2h44v17.1zm89.6 60.3h-20.1l-36.8-47.4h-.2v47.4H450V86.3h20.1l36.8 47.3h.2V86.3h20.1v77.3zM105.4 140c0 8.5-10.1 8.2-16 8.2h-2.5v-16.6h2c6.6 0 16.5-.9 16.5 8.4zm-16.3-23.3h-2.2v-15h1.4c5.4 0 11.7.4 11.7 7.5 0 6.5-5.5 7.5-10.9 7.5z"></path>
              </svg>
            </AniLink>
            <button
              className="text-white cursor-pointer  text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {navbarOpen ? (
                <MdOutlineClose className="fill-black dark:fill-white" />
              ) : (
                <BiMenu className="fill-black dark:fill-white" />
              )}
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex w-full" : " hidden")
            }
          >
            <ul
              className={
                "flex ml-0 flex-col mt-4 lg:items-center mb-0 lg:gap-6 lg:flex-row list-none lg:ml-auto" +
                (navbarOpen ? " w-full" : "")
              }
            >
              <li>
                <AniLink
                  to="/projects"
                  paintDrip
                  duration={0.5}
                  hex={
                    isBrowser() && window.localStorage.theme === "dark"
                      ? "#F9FAFB"
                      : "#17181b"
                  }
                  className="font-bold text-xs uppercase tracking-widest block py-2 pr-4 pl-3 dark:text-white text-zinc-900 border-b border-t border-zinc-900 hover:opacity-80 md:hover:bg-transparent lg:border-0 md:p-0 md:dark:hover:text-white  dark:hover:bg-zinc-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-zinc-700"
                >
                  Projects
                </AniLink>
              </li>
              <li>
                <AniLink
                  to="/services"
                  paintDrip
                  duration={0.5}
                  hex={
                    isBrowser() && window.localStorage.theme === "dark"
                      ? "#F9FAFB"
                      : "#17181b"
                  }
                  className="font-bold text-xs uppercase tracking-widest block py-2 pr-4 pl-3 dark:text-white text-zinc-900 border-b border-zinc-900  hover:opacity-80 md:hover:bg-transparent lg:border-0 md:p-0 md:dark:hover:text-white  dark:hover:bg-zinc-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-zinc-700"
                >
                  Services
                </AniLink>
              </li>
              <li>
                <AniLink
                  to="/about"
                  paintDrip
                  duration={0.5}
                  hex={
                    isBrowser() && window.localStorage.theme === "dark"
                      ? "#F9FAFB"
                      : "#17181b"
                  }
                  className="font-bold text-xs uppercase tracking-widest block py-2 pr-4 pl-3 dark:text-white text-zinc-900 border-b border-zinc-900 hover:opacity-80 md:hover:bg-transparent lg:border-0 md:p-0 md:dark:hover:text-white  dark:hover:bg-zinc-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-zinc-700"
                >
                  About
                </AniLink>
              </li>
              <li>
                <AniLink
                  to="/news"
                  paintDrip
                  duration={0.5}
                  hex={
                    isBrowser() && window.localStorage.theme === "dark"
                      ? "#F9FAFB"
                      : "#17181b"
                  }
                  className="font-bold text-xs uppercase tracking-widest block py-2 pr-4 pl-3 dark:text-white text-zinc-900 border-b border-zinc-900 hover:opacity-80 md:hover:bg-transparent lg:border-0  md:p-0 md:dark:hover:text-white  dark:hover:bg-zinc-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-zinc-700"
                >
                  News
                </AniLink>
              </li>
              <li>
                <AniLink
                  to="/support"
                  paintDrip
                  duration={0.5}
                  hex={
                    isBrowser() && window.localStorage.theme === "dark"
                      ? "#F9FAFB"
                      : "#17181b"
                  }
                  className="font-bold text-xs uppercase tracking-widest block py-2 pr-4 pl-3 dark:text-white text-zinc-900 border-b border-zinc-900 hover:opacity-80 md:hover:bg-transparent lg:border-0 md:p-0 md:dark:hover:text-white  dark:hover:bg-zinc-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-zinc-700"
                >
                  Support
                </AniLink>
              </li>
              <li>
                <AniLink
                  to="/contact"
                  paintDrip
                  duration={0.5}
                  hex={
                    isBrowser() && window.localStorage.theme === "dark"
                      ? "#F9FAFB"
                      : "#17181b"
                  }
                  className="font-bold text-xs uppercase tracking-widest block py-2 pr-4 pl-3 dark:text-white text-zinc-900 border-b border-zinc-900 hover:opacity-80 md:hover:bg-transparent lg:border-0 md:p-0 md:dark:hover:text-white  dark:hover:bg-zinc-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-zinc-700"
                >
                  Contact
                </AniLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="fixed bottom-6 right-6 flex items-center z-50">
          <button className="snipcart-checkout rounded-full shadow-xl  bg-yellow-500 dark:bg-purple-500 h-12 w-12  relative flex mt-0 align-middle items-center justify-center  text-xl text-black hover:text-zinc-700">
            <HiOutlineShoppingCart />
            {cartQuantity !== 0 && (
              <span className="absolute right-0 top-0 rounded-full bg-purple-500 dark:bg-yellow-500 font-bold w-4 h-4 pt-[0.1rem] p-0 m-0 text-white dark:text-zinc-800 text-[0.6rem] leading-tight text-center">
                {cartQuantity}
              </span>
            )}
          </button>
        </div>
      </nav>
    </>
  )
}
