module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/vercel/path0/src/components/layout.js"},
    },{
      plugin: require('../node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"Robineau","partialMatching":true,"pagePaths":["/342-EM-05-2023"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.0.29","publicApiKey":"NjUxMjU2NDktZWJhYi00NGIwLTkyYTEtNGY2OTgwNWI5MjU5NjM3OTUwNDQ4MTIxNTYxODU2","defaultLang":"en","currency":"usd","openCartOnAdd":true,"useSideCart":true,"innerHTML":"\n        <checkout section=\"header\">\n          <div>\n            <cart-header class=\"snipcart-checkout__header\" :show-items-count=\"false\" :show-summary=\"true\" :show-account-menu=\"showAccountMenu\" @summaryOpened=\"summaryOpened\" @summaryClosed=\"summaryClosed\">\n            </cart-header>\n            <div class=\"snipcart-logo-wrapper\">\n              <img src=\"https://cdn.sanity.io/images/rbhjt84j/production/5d777ffa62d905ac0218243f57a58c9ae182ddc0-650x250.png\" class=\"snipcart-budmen\"/>\n            </div>\n          </div>\n        </checkout>\n      "},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#119DA4","display":"minimal-ui","icon":"src/images/budmen-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d82e98f64af7454840e8f20c81330b36"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"gpa7pqv"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
